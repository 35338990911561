import { tv, type VariantProps } from 'tailwind-variants'

export const svgVariants = tv({
  base: 'inline-block select-none fill-current shrink-0',
  variants: {
    type: {
      text: 'w-[1em] h-[1em]'
    }
  }
})

export type Variants = VariantProps<typeof svgVariants>
