import { cn } from '~/utils/cn'
import * as styles from './SvgIcon.styles'

export type SvgIconProps = React.ComponentPropsWithoutRef<'svg'> & {
  titleAccess?: string
} & styles.Variants

const SvgIcon = ({
  titleAccess = '',
  children = null,
  className = '',
  type,
  ...props
}: SvgIconProps) => {
  return (
    <svg
      focusable="false"
      className={cn(styles.svgVariants({ type }), className)}
      aria-hidden={titleAccess ? undefined : true}
      role={titleAccess ? 'img' : undefined}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {children}
      {titleAccess ? <title>{titleAccess}</title> : null}
    </svg>
  )
}

export default SvgIcon
